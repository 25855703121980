<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">轮播图</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-button type="primary" @click="openDialog()" size="mini">添加轮播图</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="120" prop="banner_id" label="轮播图ID"></el-table-column>
				<el-table-column align="center" label="轮播图标题">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.title}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="轮播图" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.image">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="轮播图排序">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.sort}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="status" label="是否显示">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" :active-value="10" @change="handleStatus(scope.row)"
							:inactive-value="20"></el-switch>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openDialog(scope.row)" class="table-btn">修改
						</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" top="3vh" width="650px" height="60%">
				<!-- 添加/修改规则 -->
				<el-form :rules="rules" ref="form" :model="form" label-width="100px">
					<el-form-item label="轮播图标题" prop="title">
						<el-input v-model="form.title" placeholder="名称"></el-input>
					</el-form-item>
					<el-form-item label="轮播图" prop="image">
						<app-attachment :multiple="false" :max="1" v-model="form.image">
							<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
						</app-attachment>
						<app-gallery :url="form.image" :show-delete="true" @deleted="form.image = ''" width="80px"
							height="80px"></app-gallery>
					</el-form-item>
					<el-form-item label="跳转链接" prop="page_url">
						<el-input v-model="form.page_url" class="input-with-select" autocomplete="off">
							<app-pick-link @selected="selectedPageUrl" slot="append">
								<el-button size="mini">选择链接</el-button>
							</app-pick-link>
						</el-input>
					</el-form-item>
					<el-form-item label="是否显示" prop="status">
						<el-radio v-model="form.status" :value="10" :label="10">
							显示
						</el-radio>
						<el-radio v-model="form.status" :value="20" :label="20">
							隐藏
						</el-radio>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
						<el-input-number :min="0" v-model="form.sort" placeholder="排序"></el-input-number>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submit('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				lists: [],
				category: [],
				activeName: 'basic',
				dialogVisible: false,
				dialogTitle: '添加轮播图',
				form: {
					title: '',
					image: '',
					page_url: '',
					status: 10,
					sort: 50,
				},
				rules: {
					title: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'change'
					}],
					image: [{
						required: true,
						message: '请选择图片',
						trigger: 'change'
					}, ],
				},
				search: {
					s: 'store/content.banner/index',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			selectedPageUrl(page_url, param) {
				this.form.page_url = page_url;
			},
			// 打开弹出层
			openDialog(item = false) {
				// 初始化表单
				if (!item || typeof item === 'number') {
					this.form = {
						title: '',
						page_url: '',
						image: '',
						status: 10,
						sort: 50,
					}
					this.banner_id = 0
				} else {
					this.axios({
						token: true,
						params: {
							banner_id: item.banner_id,
							s: 'store/content.banner/edit'
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.dialogTitle = '编辑轮播图'
							this.form = {
								title: item.title,
								image: item.image,
								page_url: item.page_url,
								status: item.status,
								sort: item.sort,
							}
							this.banner_id = item.banner_id
						} else {
							return false;
						}
					}).catch(err => {})
				}
				// 显示弹出层
				this.dialogVisible = true
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						let params = {
							s: 'store/content.banner/add'
						}
						if (this.banner_id > 0) {
							params.s = 'store/content.banner/edit';
							params.banner_id = this.banner_id;
						}
						this.axios({
							token: true,
							params: params,
							data: {
								banner: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogVisible = false;
								this.getList()
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
			handleStatus(column) {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/content.banner/state',
						banner_id: column.banner_id,
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					if (res.data.code === 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
					} else {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定'
						})
					}
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该轮播图吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/content.banner/delete',
							banner_id: data.banner_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
